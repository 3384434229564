<template>
  <div class="d-flex d-flex-c d-flex-between flex-1">
    <div
      class="d-flex d-flex-r d-flex-between d-align-content-start section one"
    >
      <chart-wrapper
        label="Impressions"
        wrapper-type="small-c"
        :chart-config="impressionsConf"
      />
      <chart-wrapper
        class="ml-1"
        label="Unique impressions"
        wrapper-type="small-c"
        :chart-config="uniqueImpressionsConf"
      />
      <chart-wrapper
        class="ml-1"
        label="New likes"
        wrapper-type="small-c"
        :chart-config="newLikesConf"
      />
      <chart-wrapper
        class="ml-1"
        label="Total engagements"
        wrapper-type="small-c"
        :chart-config="totalEngagementsConf"
      />
    </div>
    <div
      class="d-flex d-flex-r d-flex-between d-align-content-start section two"
    >
      <chart-wrapper
        label="Impressions"
        chart-type="area"
        wrapper-type="large f-height"
        :chart-config="impressionsSeries"
      />
      <chart-wrapper
        class="ml-1"
        chart-type="line"
        label="Unique impressions"
        wrapper-type="large f-height"
        :chart-config="uniqueImpressionsSeries"
      />
    </div>
    <div
      class="d-flex d-flex-r d-flex-between d-align-content-start section two"
    >
      <chart-wrapper
        label="Follower gains"
        chart-type="area"
        wrapper-type="large f-height"
        :chart-config="followerGainsSeries"
      />
      <chart-wrapper
        class="ml-1"
        chart-type="line"
        label="Paid followers"
        wrapper-type="large f-height"
        :chart-config="padFollowerGainsSeries"
      />
    </div>
    <div
      class="d-flex d-flex-r d-flex-between d-align-content-start section two"
    >
      <chart-wrapper
        label="Page Views"
        chart-type="line"
        wrapper-type="large f-height"
        :chart-config="pageViewsSeries"
      />
      <div
        class="d-flex d-flex-r flex-wrap ml-1"
        style="flex: 1.055; height: 400px"
      >
        <chart-wrapper
          label="Likes"
          chart-type="area"
          wrapper-type="medium -h"
          :chart-config="likesSeries"
        />
        <chart-wrapper
          class="ml-1"
          label="Comments"
          chart-type="area"
          wrapper-type="medium -h"
          :chart-config="commentsSeries"
        />
        <chart-wrapper
          label="Shares"
          chart-type="area"
          wrapper-type="medium -h"
          :chart-config="sharesSeries"
        />
        <chart-wrapper
          class="ml-1"
          label="Clicks"
          chart-type="area"
          wrapper-type="medium -h"
          :chart-config="clicksSeries"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { areaChartOpts, smallAreaChartOpts } from "@/utils/chart";
export default {
  name: "LinkedInOverview",
  components: {
    ChartWrapper: () =>
      import("@/components/").then((res) => {
        return res.ChartWrapper;
      }),
  },
  props: {
    metrics: { type: Array, default: () => [] },
  },
  computed: {
    impressionsConf() {
      const impressions =
        (this.metrics.filter((metric) => metric.type === "impressionCount") ||
          [])[0] || {};

      const { data = [] } = impressions;

      const count = data.reduce((acc, curr) => {
        return acc + curr.count;
      }, 0);

      return {
        count,
      };
    },
    uniqueImpressionsConf() {
      const reach =
        (this.metrics.filter(
          (metric) => metric.type === "uniqueImpressionsCount",
        ) || [])[0] || {};

      const { data = [] } = reach;

      const count = data.reduce((acc, curr) => {
        return acc + curr.count;
      }, 0);

      return {
        count,
      };
    },
    newLikesConf() {
      const newLikes =
        (this.metrics.filter((metric) => metric.type === "likeCount") ||
          [])[0] || {};

      const { data = [] } = newLikes;

      const count = data.reduce((acc, curr) => {
        return acc + curr.count;
      }, 0);

      return {
        count,
      };
    },
    totalEngagementsConf() {
      const totalEngagementMetrics =
        (this.metrics.filter((metric) => metric.type === "engagement") ||
          [])[0] || {};

      const { data = [] } = totalEngagementMetrics;

      const count = data.reduce((acc, curr) => {
        return acc + curr.count;
      }, 0);

      return {
        count,
      };
    },
    impressionsSeries() {
      const impressionMetrics =
        (this.metrics.filter((metric) => metric.type === "impressionCount") ||
          [])[0] || {};

      const { data = [] } = impressionMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Organic impressions",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    uniqueImpressionsSeries() {
      const reachMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "uniqueImpressionsCount",
        ) || [])[0] || {};

      const { data = [] } = reachMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Organic reach",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    followerGainsSeries() {
      const followerGainsMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "organicFollowerGain",
        ) || [])[0] || {};

      const { data = [] } = followerGainsMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Follower gains",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    padFollowerGainsSeries() {
      const followerGainsMetrics =
        (this.metrics.filter((metric) => metric.type === "paidFollowerGain") ||
          [])[0] || {};

      const { data = [] } = followerGainsMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Follower gains",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    pageViewsSeries() {
      const pageViewsMetrics =
        (this.metrics.filter((metric) => metric.type === "allPageViews") ||
          [])[0] || {};

      const { data = [] } = pageViewsMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count.pageViews;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Profile views",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    commentsSeries() {
      const commentsMetrics =
        (this.metrics.filter((metric) => metric.type === "commentCount") ||
          [])[0] || {};

      const { data = [] } = commentsMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          if (Object.values(curr.count) > 0) {
            const j = xaxis.indexOf(curr.date);
            acc[j] = Object.values(curr.count).reduce((a, c) => {
              return a + c;
            }, 0);
          }

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Comments",
          data: seriesObj,
        },
      ];

      const count = data.reduce((acc, curr) => {
        acc =
          acc +
          Object.values(curr.count).reduce((a, c) => {
            return a + c;
          }, 0);
        return acc;
      }, 0);

      return {
        series,
        count,
        chartOptions: {
          ...smallAreaChartOpts,
          xaxis: {
            ...smallAreaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    likesSeries() {
      const repliesMetrics =
        (this.metrics.filter((metric) => metric.type === "likeCount") ||
          [])[0] || {};

      const { data = [] } = repliesMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          if (Object.values(curr.count) > 0) {
            const j = xaxis.indexOf(curr.date);
            acc[j] = Object.values(curr.count).reduce((a, c) => {
              return a + c;
            }, 0);
          }

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Replies",
          data: seriesObj,
        },
      ];

      const count = data.reduce((acc, curr) => {
        acc =
          acc +
          Object.values(curr.count).reduce((a, c) => {
            return a + c;
          }, 0);
        return acc;
      }, 0);

      return {
        series,
        count,
        chartOptions: {
          ...smallAreaChartOpts,
          xaxis: {
            ...smallAreaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    sharesSeries() {
      const shareMetrics =
        (this.metrics.filter((metric) => metric.type === "shareCount") ||
          [])[0] || {};

      const { data = [] } = shareMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          if (Object.values(curr.count) > 0) {
            const j = xaxis.indexOf(curr.date);
            acc[j] = Object.values(curr.count).reduce((a, c) => {
              return a + c;
            }, 0);
          }

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Shares",
          data: seriesObj,
        },
      ];

      const count = data.reduce((acc, curr) => {
        acc =
          acc +
          Object.values(curr.count).reduce((a, c) => {
            return a + c;
          }, 0);
        return acc;
      }, 0);

      return {
        series,
        count,
        chartOptions: {
          ...smallAreaChartOpts,
          xaxis: {
            ...smallAreaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    clicksSeries() {
      const savesMetrics =
        (this.metrics.filter((metric) => metric.type === "clickCount") ||
          [])[0] || {};

      const { data = [] } = savesMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          if (Object.values(curr.count) > 0) {
            const j = xaxis.indexOf(curr.date);
            acc[j] = Object.values(curr.count).reduce((a, c) => {
              return a + c;
            }, 0);
          }

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Saves",
          data: seriesObj,
        },
      ];

      const count = data.reduce((acc, curr) => {
        acc =
          acc +
          Object.values(curr.count).reduce((a, c) => {
            return a + c;
          }, 0);
        return acc;
      }, 0);

      return {
        series,
        count,
        chartOptions: {
          ...smallAreaChartOpts,
          xaxis: {
            ...smallAreaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  > div {
    flex: 1;
  }
}
</style>
